import Styles from "./index.module.scss";
import { Input, Button, Space, Table, Modal, Form, Select, Tag } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import { useMobile } from "../../hooks";
import {
  fetchData,
  postData,
  deleteData,
  putFormData,
  putData,
} from "../../endpoints";
import access from "../../assets/access";

import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const { Option } = Select;
// table req
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email address!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function Appointment() {
  // states
  const [form] = Form.useForm();
  const [data, setdata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openaddevent, setOpenaddevent] = useState(false);
  const [openeditevent, setOpeneditevent] = useState(false);
  const [deleteevent, sedDeleteEvent] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedevent, Setselectedevent] = useState({});
  const [searchedColumn, setSearchedColumn] = useState("");
  const [cal_events, setCalEvents] = useState([]);
  const searchInput = useRef(null);
  const [Cal_Users, setCal_Users] = useState([]);
  const [Users, SetUsers] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [selecteduser, Setselecteduser] = useState("");
  const isMobile = useMobile();

  useEffect(() => {
    // form.resetFields();
    fetchEvents();
  }, []);
  //function for get events
  async function fetchEvents(page = 1, isReload = false) {
    var temp = isReload ? [] : data;
    setIsLoading(true);
    if (data.length < (page - 1) * 15 + 2 || isReload) {
      var res = await fetchData(
        "admin/appointment-event/?deletedAt=false&perPage=15&page=" + page
      );
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setdata(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat(res.data.data)
          );
        } else {
          setdata(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), locationTypes: [] },
              ])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }

      var Calendly_users = await fetchData(
        "admin/appointment-event/calendly/user/index"
      );
      if (Calendly_users.status == 200) {
        setCal_Users(Calendly_users.data.data);
      }
    }

    var get_Users = await fetchData("admin/user?type=admin");
    if (get_Users.status == 200) {
      SetUsers(get_Users.data.data);
    }
    setIsLoading(false);
  }

  const countDown_add = () => {
    let secondsToGo = 5;
    const instance = modal.success({
      title: "Event added successfully!",
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({});
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
  };
  const countDown_remove = () => {
    let secondsToGo = 5;
    const instance = modal.success({
      title: "Event romoved successfully!",
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({});
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
  };
  const countDown_edit = () => {
    let secondsToGo = 5;
    const instance = modal.success({
      title: "Event edited successfully!",
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({});
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
  };

  //add event function
  async function onFinish(values) {
    setIsLoading(true);
    let temp = {
      title: values.user.title,
      description: values.user.description,
      locationTypes: values.user.locationTypes,
      code: values.user.Code,
      fixedHosts: values.user.fixedHost,
      type:values.user.type,
      url: " ",
    };

    var res = await postData("admin/appointment-event", temp);
    if (res.status == 201) {
      // setdata(data.push(temp));
      setdata([]);
      await fetchEvents(1, true);
      form.resetFields();
      setOpenaddevent(false);
      countDown_add();
    } else {
      setIsLoading(false);
      setOpenaddevent(false);
      Modal.error({
        title: res,
      });
    }
  }
  //edit event function
  async function edit_event() {
    setIsLoading(true);
    var myTemp = selectedevent;
    if (selectedevent.fixedHosts.length == 0) {
      delete myTemp.fixedHosts;
    }
    var res = await putData(
      `admin/appointment-event/${selectedevent.id}`,
      myTemp
    );
    if (res.status == 200) {
      setdata([]);
      await fetchEvents(1, true);
      Setselectedevent({});
      setOpeneditevent(false);
      countDown_edit();
      form.resetFields();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setOpeneditevent(false);
      Modal.error({
        title: res,
      });
    }
  }
  //remove event function
  async function remove_event() {
    setIsLoading(true);
    var res = await deleteData(`admin/appointment-event/${selectedevent.id}`);
    if (res.status == 200) {
      setdata([]);
      await fetchEvents(1, true);
      sedDeleteEvent(false);
      countDown_remove();
    } else {
      setIsLoading(false);
      sedDeleteEvent(false);
      Modal.error({
        title: res,
      });
    }
  }

  //table req
  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setIsLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setdata([]);
      await fetchEvents(1, true);
    } else {
      let search = "";
      if (dataIndex == "title") search = "title";
      if (dataIndex == "description") search = "description";
      if (dataIndex == "code") search = "code";

      var res = await fetchData(
        `admin/appointment-event/?${search}=` + selectedKeys[0]
      );
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });
      if (res.status == 200) {
        setdata(res.data.data);
      }
    }
    setIsLoading(false);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdata([]);
    await fetchEvents(1, true);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  //table columns
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "15%",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Location Type",
      dataIndex: "locationTypes",
      key: "locationTypes",
      width: "20%",
      // ...getColumnSearchProps("locationTypes"),

      responsive: ["md"],
      render: (locationTypes, record) => (
        <div>
          {locationTypes.map((s, i) => {
            let color = ["geekblue", "purple", "magenta"];

            return (
              <Tag color={color[i % 3]} key={s}>
                {s.type == "online_need_translation" ||
                s.type == "offline_need_translation"
                  ? s.type == "online_need_translation"
                    ? "online_with_interpreter"
                    : "offline_with_interpreter"
                  : s.type}
              </Tag>
            );
          })}
        </div>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "15%",
      ...getColumnSearchProps("code"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "45%",
      ...getColumnSearchProps("description"),
    },
    privilage.indexOf(access.eventManegment.edit) > -1 && {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <a
          onClick={() => {
            Setselectedevent({
              ...u,
              fixedHosts: u.fixedHosts.map((f) => {
                return f.userId;
              }),
              locationTypes: u.locationTypes.map((f) => {
                return f.type;
              }),
            });
            setOpeneditevent(true);
          }}
          style={{ color: "black" }}
        >
          Edit
        </a>
      ),
    },
    privilage.indexOf(access.eventManegment.delete) > -1 && {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <a
          onClick={() => {
            Setselectedevent(u);
            sedDeleteEvent(true);
          }}
          style={{ color: "red" }}
        >
          Remove
        </a>
      ),
    },
  ];

  //table selecting
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      {contextHolder}
      <div className={Styles.wrapp}>
        <div className={Styles.Events_wrapp}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className={`${
                isMobile
                  ? "forms d-flex flex-column gap-3 py-4 w-100"
                  : "forms d-flex flex-column gap-3 py-4 px-3 w-100"
              }`}
              style={
                isMobile
                  ? { fontSize: "18px", fontWeight: "bold" }
                  : { fontSize: "24px", fontWeight: "bold" }
              }
            >
              Events
            </div>
          </div>
        </div>
        <div
          style={
            isMobile
              ? { width: "87vw", padding: "0px 20px" }
              : { padding: "0px 55px" }
          }
        >
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            // rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={{
              onChange: async (page) => {
                await fetchEvents(page);
              },
              // total: 50,
              pageSize: 15,
            }}
            footer={() => {
              if (privilage.indexOf(access.eventManegment.add) > -1)
                return (
                  <div
                    onClick={() => {
                      setOpenaddevent(true);
                    }}
                    className="d-flex fle-row gap-2 px-4 align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <PlusOutlined
                      style={{
                        color: "rgba(46, 108, 223, 1)",
                      }}
                    />
                    <h6
                      style={{
                        color: "rgba(46, 108, 223, 1)",
                        marginBottom: "0",
                      }}
                    >
                      Add Event
                    </h6>
                  </div>
                );
            }}
          />
        </div>
      </div>
      <Modal
        okText={"Add"}
        centered
        open={openaddevent}
        footer={null}
        onOk={() => setOpenaddevent(false)}
        onCancel={() => {
          setOpenaddevent(false);
          form.resetFields();
          setCalEvents([]);
        }}
        confirmLoading={isLoading}
      >
        <Form
          form={form}
          {...layout}
          onFinish={onFinish}
          validateMessages={validateMessages}
          loading={isLoading}
        >
          <div className={Styles.form_header}>Add Event</div>
          <Form.Item
            name={["user", "title"]}
            label="Title"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name={["user", "description"]} label="Description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name={["user", "locationTypes"]}
            label="Location Type"
            rules={[{ required: true, type: "array" }]}
          >
            <Select mode="multiple">
              <Select.Option value="online">Online</Select.Option>
              <Select.Option value="in_person">In-Person</Select.Option>
              <Select.Option value="online_form">Online form</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={["user", "type"]}
            label="Type"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="public">Public</Select.Option>
              <Select.Option value="private">Private</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={["user", "fixedHost"]}
            label="FixedHost"
            rules={[
              {
                required: true,
                type: "array",
              },
            ]}
          >
            <Select mode="multiple">
              {Users.map((user) => {
                return (
                  <Option value={user.id}>
                    {user.firstName + " " + user.lastName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name={["user", "User"]}
            label="User"
            rules={[{ required: true }]}
          >
            <Select
              onSelect={async (u) => {
                setIsLoading(true);
                setCalEvents([]);
                Setselecteduser(u);
                var Calendly_events = await fetchData(
                  "admin/appointment-event/calendly/event/index/?user=" + u
                );
                if (Calendly_events.status == 200) {
                  setCalEvents(Calendly_events.data.data);
                }
                setIsLoading(false);
              }}
            >
              {Cal_Users.map((User, index) => {
                return (
                  <Select.Option value={User.id}>{User.name}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {cal_events.length > 1 ? (
            <Form.Item
              name={["user", "Code"]}
              label="Code"
              rules={[{ required: true }]}
            >
              <Select>
                {cal_events.map((cal_event, index) => {
                  return (
                    <Select.Option value={cal_event.id}>
                      {cal_event.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : (
            <></>
          )}

          <Form.Item className={Styles.form_btn}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
            Confirm
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Delete Event"
        open={deleteevent}
        onOk={remove_event}
        onCancel={() => sedDeleteEvent(false)}
      >
        <p>Are you sure to delete this event?</p>
      </Modal>
      <Modal
        okText={"Edit"}
        centered
        open={openeditevent}
        footer={null}
        onCancel={() => {
          Setselectedevent({});
          setOpeneditevent(false);
        }}
      >
        <Form form={form} {...layout} validateMessages={validateMessages}>
          <div
            className={Styles.form_header}
            style={isMobile ? { fontSize: "18px" } : {}}
          >
            Edit Event
          </div>
          <Form.Item
            // name={["user", "title"]}
            label="Title"
            rules={[{ required: false }]}
          >
            <Input
              onChange={(e) => {
                Setselectedevent({ ...selectedevent, title: e.target.value });
              }}
              value={selectedevent.title}
            />
          </Form.Item>
          <Form.Item
            // name={["user", "description"]}
            label="descriptin"
            rules={[{ required: false }]}
          >
            <Input.TextArea
              onChange={(e) => {
                Setselectedevent({
                  ...selectedevent,
                  description: e.target.value,
                });
              }}
              value={selectedevent.description}
            />
          </Form.Item>
          <Form.Item
            label="Type" 
          >
            <Select 
            value={selectedevent.type}
            onChange={(e) => {
              Setselectedevent({
                ...selectedevent,
                type: e,
              });
            }}>
              <Select.Option value={"public"}>Public</Select.Option>
              <Select.Option value={"private"}>Private</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="fixedHost"
            rules={[
              {
                required: false,
                type: "array",
              },
            ]}
          >
            <Select
              mode="multiple"
              onChange={(e) => {
                Setselectedevent({
                  ...selectedevent,
                  fixedHosts: e,
                });
              }}
              value={selectedevent.fixedHosts}
            >
              {Users.map((user) => {
                return (
                  <Select.Option value={user.id}>
                    {user.firstName + " " + user.lastName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Location Type"
            rules={[{ required: false, type: "array" }]}
          >
            <Select
              mode="multiple"
              onChange={(e) => {
                Setselectedevent({
                  ...selectedevent,
                  locationTypes: e,
                });
              }}
              value={selectedevent.locationTypes}
            >
              <Select.Option value="online">Online</Select.Option>
              <Select.Option value="in_person">In-Person</Select.Option>
              <Select.Option value="online_form">Online form</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={["user", "User"]}
            label="User"
            rules={[{ required: false }]}
          >
            <Select
              value={selecteduser}
              onSelect={async (u) => {
                setIsLoading(true);
                setCalEvents([]);
                Setselecteduser(u);
                var Calendly_events = await fetchData(
                  "admin/appointment-event/calendly/event/index/?user=" + u
                );
                if (Calendly_events.status == 200) {
                  setCalEvents(Calendly_events.data.data);
                }
                setIsLoading(false);
              }}
            >
              {Cal_Users.map((User, index) => {
                return (
                  <Select.Option value={User.id}>{User.name}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {cal_events.length > 1 ? (
            <Form.Item
              name={["user", "Code"]}
              label="Code"
              rules={[{ required: false }]}
            >
              <Select
                onChange={(e) => {
                  Setselectedevent({ ...selectedevent, code: e.target.value });
                }}
              >
                {cal_events.map((cal_event, index) => {
                  return (
                    <Select.Option value={cal_event.id}>
                      {cal_event.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : (
            <></>
          )}

          <Form.Item className={Styles.form_btn}>
            <Button
              type="primary"
              loading={isLoading}
              onClick={edit_event}
              htmlType="submit"
            >
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Appointment;
