import {
  deleteData,
  fetchData,
  postData,
  postFormData,
  putData,
} from "../../../endpoints";
import { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Mentions,
  Modal,
  Select,
  Spin,
  Tag,
  Upload,
  message,
} from "antd";
import ReactQuill from "react-quill";
import {
  InboxOutlined,
  MinusCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import { useMobile } from "../../../hooks";
import getPeaks from "../../Report/CropVoice/src/peaks";
const AddComment = ({
  isOpen,
  getComments,
  setIsOpen,
  patients,
  purchase,
  selecting = false,
  defultComment = {},
  setDefult,
}) => {
  // console.log(defultComment, "defauuuuuuuuuult" );
  const listOfColor = [
    "red",
    "lime",
    "geekblue",
    "green",
    "orange",
    "cyan",
    "blue",
    "magenta",
    "purple",
    "volcano",
    "gold",
  ];
  const isMobile = useMobile();
  const [purchaseService, setPurchase] = useState(purchase ?? []);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [comment, setComment] = useState(defultComment ?? {});
  const [purchaseStep, setPurchaseStep] = useState([]);
  const [users, setUsers] = useState([]);
  const [attachs, setAttachs] = useState([]);
  const [watchers, setWatchers] = useState([]);
  const [assigns, setAssigns] = useState([]);
  const [temp, setTemp] = useState({});
  const [searchPatients, setSearchPatients] = useState(patients);
  const [searchUsers, setSearchUsers] = useState([]);
  const [patientvalue, setpatientvalue] = useState("");
  const { Dragger } = Upload;
  useEffect(() => {
    if (!isOpen) {
      handleClose();
      setpatientvalue("");
      setIsOpen(false);
    } else {
      setLoading(true);
      getUsers();
      setLoading(false);
      if (!purchase) {
        getPurchase();
      }
      if (defultComment) {
        setComment(defultComment);
        if (defultComment.purchaseId) {
          getPurchaseStep(defultComment.purchaseId);
        }
      }
    }
  }, []);
  const handleClose = async () => {
    setStep(1);
    setDefult({});
    setAssigns([]);
    setWatchers([]);
    setAttachs([]);
    setComment({ id: null });
  };
  const getPurchase = async () => {
    var res = await fetchData(
      `admin/service-purchase/?&status=in_progress&includes[]=patient&perPage=200&page=1`
    );
    if (res.status == 200) {
      setPurchase(res.data.data);
    } else {
      Modal.error({ title: res });
    }
  };
  const upload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    setLoading(true);
    var x = attachs.filter((a) => {
      return a.title == file.name;
    });
    if (x.length == 0) {
      const data = new FormData();
      data.append("file", file);
      data.append("commentId", comment.id);
      data.append("title", file.name);
      var res = await postFormData("admin/comment-attachment", data);
      if (res.status == 201) {
        onSuccess("Ok");
        setAttachs([
          ...attachs,
          { file: file, title: file.name, id: res.data.data.id },
        ]);
      } else {
        onError(Modal.error({ title: res }));
      }
    }
    setLoading(false);
  };
  const props = {
    name: "file",
    multiple: true,
    customRequest: upload,
    async onChange(info) {},
  };
  const getUsers = async () => {
    setLoading(true);

    var res = await fetchData(
      `admin/user/?disabledAt=false&deletedAt=false&perPage=100&page=1&type=admin&type=super_user`
    );

    if (res.status == 200) {
      setUsers(res.data.data);
    } else {
      Modal.error({ title: res });
    }
    setLoading(false);
  };
  const getSearchUsers = async (email = null) => {
    setLoading(true);
    if (email) {
      var res = await fetchData(
        `admin/user/?disabledAt=false&deletedAt=false&type=admin&type=super_user&email=${email}`
      );
    } else {
      var res = await fetchData(
        `admin/user/?disabledAt=false&deletedAt=false&type=admin&type=super_user&perPage=100&page=1`
      );
    }
    if (res.status == 200) {
      setSearchUsers(res.data.data);
    } else {
      Modal.error({ title: res });
    }
    setLoading(false);
  };
  const getPatient = async (value) => {
    setLoading(true);
    if (value) {
      var res = await fetchData(
        `admin/patient?deletedAt=false&verifiedAt=true&firstName=${value}`
      );
    } else {
      var res = await fetchData(
        `admin/patient?deletedAt=false&verifiedAt=true&perPage=100&page=1`
      );
    }
    // console.log(res.data.data);
    setSearchPatients(res.data.data);

    setLoading(false);
  };
  const getPurchaseStep = async (serviceId) => {
    setLoading(true);
    var res = await fetchData(
      `admin/purchase-step/?includes[]=serviceStep&purchaseId=` + serviceId
    );
    if (res.status == 200) {
      setPurchaseStep(res.data.data);
    } else {
      Modal.error({ title: res });
    }
    setLoading(false);
  };
  const convertStringToHTML = (htmlString) => {
    let element = document.getElementById("myElement");
    if (element) element.innerHTML = htmlString;
  };

  var modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  var formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];
  function onChange(e) {
    convertStringToHTML(e);
    setComment({ ...comment, text: e });
  }
  const add = async () => {
    setLoading(true);
    if (!comment.text) {
      Modal.error({ title: "Text is requierd!" });
      setLoading(false);
      return;
    }
    if (defultComment.parentId) {
      var temp = comment;
      temp = {
        ...comment,
        parentId: defultComment.parentId,
      };
      var res = await postData("admin/comment", temp);
    } else {
      var res = await postData("admin/comment", comment);
    }
    if (res.status == 201) {
      setComment({
        ...comment,
        id: res.data.data.id,
        parentId: res.data.data.parentId,
      });
    } else {
      Modal.error({ title: res });
    }
    setLoading(false);
  };
  const edit = async () => {
    setLoading(true);
    if (!comment.text) {
      Modal.error({ title: "Text is requierd!" });
      setLoading(false);
      return;
    }
    var res = await putData(`admin/comment/${comment.id}`, comment);
    if (res.status !== 200) {
      Modal.error({ title: res });
    }
    setLoading(false);
  };
  const assign = async () => {
    setLoading(true);
    var temp = assigns;
    for (let i in assigns) {
      const element = assigns[i];
      if (element.userId != {}) {
        var res = await postData("admin/comment-assignee", {
          commentId: comment.id,
          userId: element.userId,
        });
        if (res.status == 201) {
          temp[i] = { ...temp[i], id: res.data.data.id };
        } else {
          Modal.error({ title: res });
          setLoading(false);
          return;
        }
      }
    }
    setStep(step + 1);
    setAssigns(temp);
    setLoading(false);
  };
  const edit_assign = async () => {
    setLoading(true);
    var temp = assigns;
    for (let i in assigns) {
      const element = assigns[i];
      if (element.userId != {} && !element.id) {
        var res = await postData("admin/comment-assignee", {
          commentId: comment.id,
          userId: element.userId,
        });
        if (res.status == 201) {
          temp[i] = { ...temp[i], id: res.data.data.id };
        } else {
          Modal.error({ title: res });
          setLoading(false);
          return;
        }
      }
    }
    setStep(step + 1);
    setAssigns(temp);
    setLoading(false);
  };
  const watcher = async () => {
    setLoading(true);
    var temp = watchers;
    for (let i in watchers) {
      const element = watchers[i];
      if (element.userId != {}) {
        var res = await postData("admin/comment-watcher", {
          commentId: comment.id,
          userId: element.userId,
        });
        if (res.status == 201) {
          temp[i] = { ...temp[i], id: res.data.data.id };
        } else {
          Modal.error({ title: res });
          setLoading(false);
          return;
        }
      }
    }
    setStep(step + 1);
    setWatchers(temp);
    setLoading(false);
  };
  const edit_watcher = async () => {
    setLoading(true);
    var temp = watchers;
    for (let i in watchers) {
      const element = watchers[i];
      if (element.userId != {} && !element.id) {
        var res = await postData("admin/comment-watcher", {
          commentId: comment.id,
          userId: element.userId,
        });
        if (res.status == 201) {
          temp[i] = { ...temp[i], id: res.data.data.id };
        } else {
          Modal.error({ title: res });
          setLoading(false);
          return;
        }
      }
    }
    setStep(step + 1);
    setWatchers(temp);
    setLoading(false);
  };
  return (
    <div
      className={
        isMobile
          ? "d-flex flex-column col-12 align-items-center justify-content-center"
          : "d-flex flex-column px-3  col-12 align-items-center justify-content-center"
      }
    >
      <svg
        onClick={async () => {
          setLoading(true);
          await getComments();
          handleClose();
          setpatientvalue("");
          setIsOpen(false);
          setLoading(false);
        }}
        style={{ marginLeft: "98%" }}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_5044_116336)">
          <path
            d="M9.71012 8.00014L15.6459 2.06441C16.118 1.59228 16.118 0.826413 15.6459 0.354269C15.1738 -0.11809 14.4079 -0.11809 13.9358 0.354269L7.99997 6.29025L2.06442 0.354507C1.59229 -0.117852 0.826181 -0.117852 0.354272 0.354507C-0.118091 0.826633 -0.118091 1.5925 0.354272 2.06465L6.28982 8.00014L0.354272 13.9359C-0.118091 14.408 -0.118091 15.1739 0.354272 15.646C0.590333 15.8821 0.899664 16 1.2092 16C1.51852 16 1.82811 15.8821 2.06413 15.646L7.99991 9.71052L13.9357 15.646C14.1718 15.8821 14.4815 16 14.7906 16C15.0997 16 15.4095 15.8821 15.6455 15.646C16.1177 15.1739 16.1177 14.408 15.6455 13.9359L9.71012 8.00014Z"
            fill="#4C4C4C"
          />
        </g>
        <defs>
          <clipPath id="clip0_5044_116336">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <h1
        style={
          isMobile
            ? { fontSize: "18px", fontWeight: "700" }
            : { fontSize: "24px", fontWeight: "700" }
        }
      >
        Add New {defultComment.parentId ? "Reply" : "Comment"}
      </h1>

      <div
        className={
          isMobile
            ? "d-flex flex-column gap-1 col-12 justify-content-center"
            : "d-flex flex-column gap-4 col-12 justify-content-center"
        }
      >
        <div
          className="mt-3"
          style={isMobile ? {} : { display: "flex", alignItems: "center" }}
        >
          <div style={{ display: "flex", width: "11%" }}>
            <div>Text:</div>
            <div
              style={{
                color: "#ff4d4f",
                fontSize: "22px",
                marginTop: "-5px",
              }}
            >
              *
            </div>
          </div>
          <ReactQuill
            className={isMobile ? "col-12" : " col-10"}
            value={comment.text}
            formats={formats}
            modules={modules}
            onChange={onChange}
          />
        </div>

        <div className={isMobile ? "" : "d-flex flex-row col-12"}>
          <div
            className={
              isMobile
                ? " gap-1  align-items-center col-12 my-1"
                : "d-flex flex-row gap-3  align-items-center col-6"
            }
          >
            <span className="col-2" style={{ display: "flex" }}>
              Priority:{" "}
            </span>
            <Select
              allowClear
              className={isMobile ? "col-12" : "col-8"}
              value={comment.priority}
              onChange={(value) => {
                setComment({ ...comment, priority: value });
              }}
            >
              <Select.Option value={1}>Urgent</Select.Option>
              <Select.Option value={2}>High</Select.Option>
              <Select.Option value={3}>Normal</Select.Option>
              <Select.Option value={4}>Low</Select.Option>
            </Select>
          </div>
          <div
            className={
              isMobile
                ? " gap-1  align-items-center col-12 my-1"
                : "d-flex flex-row gap-3  align-items-center col-6"
            }
          >
            <span className="col-2" style={{ display: "flex" }}>
              Deadline:{" "}
            </span>
            <DatePicker
              className={isMobile ? "col-12" : "col-8"}
              value={comment.dueDate}
              onChange={(value) => {
                setComment({ ...comment, dueDate: value });
              }}
            />
          </div>
        </div>
        <div className={isMobile ? "" : "d-flex flex-row col-12"}>
          <div
            className={
              isMobile
                ? " gap-2  align-items-center col-12 my-1"
                : "d-flex flex-row gap-3  align-items-center col-6"
            }
          >
            <span className="col-2" style={{ display: "flex" }}>
              Patient:{" "}
            </span>

            <Mentions
              placeholder="@first name"
              defaultValue={
                defultComment.patient
                  ? defultComment.patient.firstName +
                    " " +
                    defultComment.patient.lastName
                  : ""
              }
              loading={loading}
              value={
                defultComment.patient
                  ? defultComment.patient.firstName +
                    " " +
                    defultComment.patient.lastName
                  : patientvalue
              }
              onChange={(value) => {
                setpatientvalue(value);
              }}
              className={isMobile ? "col-12" : "col-8"}
              onSearch={async (value) => {
                await getPatient(value);
              }}
              onSelect={async (value) => {
                setComment({
                  ...comment,
                  patientId: value.id,
                  patient: {
                    firstName: value.firstName,
                    lastName: value.lastName,
                  },
                });
              }}
              options={searchPatients.map((p) => {
                return {
                  value: `${p.firstName + " " + p.lastName}`,
                  label: p.firstName + " " + p.lastName,
                  id: p.id,
                };
              })}
            />
          </div>
          <div
            className={
              isMobile
                ? " gap-2  align-items-center col-12 my-1"
                : "d-flex flex-row gap-3  align-items-center col-6"
            }
          >
            <span className="col-2" style={{ display: "flex" }}>
              Service:{" "}
            </span>
            <Select
              allowClear
              className={isMobile ? "col-12" : "col-8"}
              value={selecting ? defultComment.purchaseId : comment.purchaseId}
              onChange={async (value) => {
                setComment({ ...comment, purchaseId: value });
                if (value) {
                  await getPurchaseStep(value);
                }
              }}
            >
              {selecting && defultComment.purchaseId ? (
                <></>
              ) : comment.patientId ? (
                purchaseService
                  .filter((service) => {
                    return service.patientId == comment.patientId;
                  })
                  .map((p) => {
                    return (
                      <Select.Option value={p.id}>
                        {p.service.title +
                          "-" +
                          p.patient.firstName +
                          " " +
                          p.patient.lastName}
                      </Select.Option>
                    );
                  })
              ) : (
                purchaseService.map((p) => {
                  return (
                    <Select.Option value={p.id}>
                      {p.service.title +
                        "-" +
                        p.patient.firstName +
                        " " +
                        p.patient.lastName}
                    </Select.Option>
                  );
                })
              )}
            </Select>
          </div>
        </div>
        <div>
          <div
            className="d-flex flex-row col-12"
            className={
              isMobile
                ? " gap-4 align-items-center "
                : "d-flex flex-row gap-3 align-items-center col-6"
            }
          >
            <span className="col-2" style={{ display: "flex" }}>
              Step:{" "}
            </span>
            <Select
              allowClear
              loading={loading}
              className={isMobile ? "col-12" : "col-8"}
              value={
                selecting
                  ? defultComment.purchaseStepId
                  : comment.purchaseStepId
              }
              onChange={(value) => {
                setComment({ ...comment, purchaseStepId: value });
              }}
            >
              {selecting && defultComment.purchaseStepId ? (
                <></>
              ) : (
                purchaseStep.map((p) => {
                  return (
                    <Select.Option value={p.id}>
                      {p.serviceStep.title}
                    </Select.Option>
                  );
                })
              )}
            </Select>
          </div>
        </div>
        {comment.id && (
          <>
            {" "}
            <div className={isMobile ? "" : "d-flex flex-row col-12"}>
              <div
                className={
                  isMobile
                    ? " gap-2  align-items-center col-12 my-1"
                    : "d-flex flex-row gap-3  align-items-center col-6"
                }
              >
                <span className="col-2" style={{ display: "flex" }}>
                  Assignees:
                </span>
                {isMobile ? (
                  <div style={isMobile ? { display: "flex" } : {}}>
                    <Mentions
                      className={isMobile ? "col-12" : "col-8"}
                      // defaultValue="@"
                      placeholder="@email"
                      loading={loading}
                      onSelect={(value) => {
                        setTemp(
                          searchUsers.filter((u) => {
                            return u.email == value.key;
                          })[0].id
                        );
                      }}
                      options={searchUsers.map((u) => {
                        if (assigns.indexOf({ userId: u.id }) == -1)
                          return { value: u.email, label: u.email };
                      })}
                      onSearch={(value) => {
                        getSearchUsers(value);
                      }}
                    />
                    <PlusCircleFilled
                      style={{ marginLeft: "-25px", zIndex: "5" }}
                      onClick={() => {
                        // console.log(assigns);
                        if (temp) {
                          setAssigns([...assigns, { userId: temp }]);
                          setTemp(null);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <Mentions
                      className={isMobile ? "col-12" : "col-8"}
                      placeholder="@email"
                      loading={loading}
                      onSelect={(value) => {
                        setTemp(
                          searchUsers.filter((u) => {
                            return u.email == value.key;
                          })[0].id
                        );
                      }}
                      options={searchUsers.map((u) => {
                        if (assigns.indexOf({ userId: u.id }) == -1)
                          return { value: u.email, label: u.email };
                      })}
                      onSearch={(value) => {
                        getSearchUsers(value);
                      }}
                    />
                    <PlusCircleFilled
                      style={{ marginLeft: "-35px", zIndex: "5" }}
                      onClick={() => {
                        // console.log(assigns);
                        if (temp) {
                          setAssigns([...assigns, { userId: temp }]);
                          setTemp(null);
                        }
                      }}
                    />
                  </>
                )}
              </div>

              <div
                className={
                  isMobile
                    ? " gap-2  align-items-center col-12 my-1"
                    : "d-flex flex-row gap-3  align-items-center col-6"
                }
              >
                <span className="col-2" style={{ display: "flex" }}>
                  Viewers:{" "}
                </span>

                {isMobile ? (
                  <div style={isMobile ? { display: "flex" } : {}}>
                    <Mentions
                      className={isMobile ? "col-12" : "col-8"}
                      placeholder="@email"
                      loading={loading}
                      onSelect={(value) => {
                        setTemp(
                          searchUsers.filter((u) => {
                            return u.email == value.key;
                          })[0].id
                        );
                      }}
                      options={searchUsers.map((u) => {
                        if (watchers.indexOf({ userId: u.id }) == -1)
                          return { value: u.email, label: u.email };
                      })}
                      onSearch={(value) => {
                        getSearchUsers(value);
                      }}
                    />
                    <PlusCircleFilled
                      style={{ marginLeft: "-25px", zIndex: "5" }}
                      onClick={() => {
                        if (temp) {
                          setWatchers([...watchers, { userId: temp }]);
                          setTemp(null);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <Mentions
                      className={isMobile ? "col-12" : "col-8"}
                      placeholder="@email"
                      loading={loading}
                      onSelect={(value) => {
                        setTemp(
                          searchUsers.filter((u) => {
                            return u.email == value.key;
                          })[0].id
                        );
                      }}
                      options={searchUsers.map((u) => {
                        if (watchers.indexOf({ userId: u.id }) == -1)
                          return { value: u.email, label: u.email };
                      })}
                      onSearch={(value) => {
                        // alert(value)
                        getSearchUsers(value);
                      }}
                    />
                    <PlusCircleFilled
                      style={{ marginLeft: "-35px", zIndex: "5" }}
                      onClick={() => {
                        console.log(watchers);
                        if (temp) {
                          setWatchers([...watchers, { userId: temp }]);
                          setTemp(null);
                        }
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            {isMobile ? (
              <></>
            ) : (
              <div className="d-flex flex-row col-12">
                <div
                  style={{ marginLeft: "20px" }}
                  className={isMobile ? "row  col-12" : "row  col-6"}
                >
                  {assigns.map((a, index) => {
                    return users
                      .filter((u) => {
                        return u.id == a.userId;
                      })
                      .map((x) => {
                        return (
                          <div
                            className={isMobile ? "col-12 mb-2" : "col-5 mb-2"}
                          >
                            <Tag
                              color={listOfColor[index % listOfColor.length]}
                              className="d-flex flex-row gap-1"
                            >
                              <MinusCircleFilled
                                onClick={async () => {
                                  setLoading(true);
                                  if (a.id) {
                                    var res = await deleteData(
                                      `admin/comment-assignee/${a.id}`
                                    );
                                    if (res.status == 200) {
                                      var template = assigns;
                                      template.splice(index, 1);
                                      setAssigns([...template]);
                                    }
                                  } else {
                                    var template = assigns;
                                    template.splice(index, 1);
                                    setAssigns([...template]);
                                  }
                                  setLoading(false);
                                }}
                              />
                              {isMobile
                                ? x.email
                                : x.email.substr(0, 12) + "..."}
                            </Tag>
                          </div>
                        );
                      });
                  })}
                </div>
                <div className="row col-6 ">
                  {watchers.map((a, index) => {
                    return users
                      .filter((u) => {
                        return u.id == a.userId;
                      })
                      .map((x) => {
                        return (
                          <div
                            style={{ marginLeft: "20px" }}
                            className="col-5 mb-2"
                          >
                            <Tag
                              color={
                                listOfColor[(index + 2) % listOfColor.length]
                              }
                              className="d-flex flex-row gap-1"
                            >
                              <MinusCircleFilled
                                onClick={async () => {
                                  setLoading(true);
                                  if (a.id) {
                                    var res = await deleteData(
                                      `admin/comment-watcher/${a.id}`
                                    );
                                    if (res.status == 200) {
                                      var template = watchers;
                                      template.splice(index, 1);
                                      setWatchers([...template]);
                                    }
                                  } else {
                                    var template = watchers;
                                    template.splice(index, 1);
                                    setWatchers([...template]);
                                  }
                                  setLoading(false);
                                }}
                              />
                              {x.email.substr(0, 12) + "..."}
                            </Tag>
                          </div>
                        );
                      });
                  })}
                </div>
              </div>
            )}
            <div className="d-flex mb-4 px-3 flex-row col-12">
              <Dragger
                className="col-12"
                {...props}
                onRemove={async (e) => {
                  setLoading(true);
                  var id;
                  attachs.map((att, index) => {
                    if ((att.title = e.name)) {
                      id = att.id;
                      attachs.splice(index, 1);
                    }
                  });
                  var res = await deleteData(`admin/comment-attachment/${id}`);
                  if (res.status == 200) {
                    console.log("success");
                  }
                  setLoading(false);
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </div>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            className={isMobile ? "col-5" : "col-2"}
            loading={loading}
            onClick={async () => {
              if (comment.id) {
                setLoading(true);
                await edit();
                await assign();
                await watcher();
                await getComments(1, true);
                setComment({});
                await handleClose();
                setpatientvalue("");
                setIsOpen(false);
                setLoading(false);
              } else {
                await add();
              }
            }}
            size={isMobile ? "small" : "large"}
            type="primary"
          >
            {"Add Comment"}
          </Button>
          <Button
            className={isMobile ? "col-4" : ""}
            style={{ marginLeft: "20px" }}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await getComments(1, true);
              await handleClose();
              setpatientvalue("");
              setLoading(false);
              setIsOpen(false);
            }}
            size={isMobile ? "small" : "large"}
            type="default"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddComment;
