import {
  Modal,
  Table,
  Input,
  Space,
  Button,
  Form,
  Steps,
  theme,
  Radio,
} from "antd";
import { useState, useEffect, useRef } from "react";
import {
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { fetchData, postData, deleteData, putData } from "../../endpoints";
import { useMobile } from "../../hooks";

const Admins = (user) => {
  const [admins, setAdmins] = useState([]);
  const isMobile = useMobile();
  const [editAdmin, setediAdmin] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const searchInput = useRef(null);
  const [current, setCurrent] = useState(0);

  const [editAdminOpen, seteditAdminOpen] = useState(false);

  const getUsers = async (page = 1, reload = false) => {
    setLoading(true);
    var res = await fetchData(
      "admin/user/?disabledAt=false&deletedAt=false&perPage=400&type[]=admin&page=" +
        page
    );
    if (res.status === 200) {
      setAdmins(res.data.data);
    } else {
      Modal.error({ title: res });
    }
    setLoading(false);
  };
  useEffect(() => {
    getUsers();
  }, []);
  const { confirm } = Modal;

  const showDeleteConfirm = async (arr) => {
    confirm({
      title: "Are you sure you want to disable this patient?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeGrupe(arr);
      },
      onCancel() {},
    });
  };
  const removeUser = async (id) => {
    var res = await deleteData(`admin/user/${id}`);
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  const removeGrupe = async (listOfUsers) => {
    let isOk = true;
    await listOfUsers.map(async (e) => {
      var res = await removeUser(e.id);
      if (!res) {
        isOk = false;
      }
    });
    if (isOk) {
      await getUsers();
      setSelectedRowKeys([]);
      Modal.success({ title: "Users have been successfully deleted." });
    } else {
      Modal.error({ title: "There was a problem, please try again." });
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...getColumnSearchProps("name"),
      render: (u) => <div>{u ? u : "name not provide"}</div>,
    },
    {
      title: "Email/Phone Number",
      dataIndex: "email",
      key: "email",
      width: "30%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "20%",
      ...getColumnSearchProps("type"),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <a
          onClick={async (e) => {
            e.stopPropagation();
            await showDeleteConfirm([u]);
          }}
          style={{ color: "#BC2323" }}
        >
          Disable
        </a>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <a
          onClick={async (e) => {
            setLoading(true);
            var res = await fetchData("admin/user-role?userId=" + u.id);

            if (res.status == 200) {
              if (res.data.data[0]) {
                setediAdmin(res.data.data[0]);
                seteditAdminOpen(true);
              } else {
                Modal.error({ title: "Role is Empty" });
              }
            } else {
              Modal.error({ title: res });
            }
            setLoading(false);
          }}
        >
          Edit
        </a>
      ),
    },
  ];
  const data = admins.map((a, i) => {
    return { ...a, key: i, name: a.firstName + " " + a.lastName };
  });
  return (
    <div
      style={isMobile ? {} : { marginLeft: "-40px" }}
      className={
        isMobile ? "d-flex flex-column px-1" : "d-flex flex-column  py-3 px-5"
      }
      dir="ltr"
    >
      <h2
        style={
          isMobile
            ? { fontSize: "18px", fontWeight: "700", marginLeft: "5%" }
            : { fontSize: "24px", fontWeight: "700", marginLeft: "5%" }
        }
      >
        Admins
      </h2>
      <div className="d-flex flex-row gap-3 py-1 px-3" dir="rtl">
        {selectedRowKeys.length > 0 && (
          <>
            <span
              onClick={() => {
                setSelectedRowKeys([]);
              }}
            >
              Cancel
            </span>
            <span
              onClick={async () => {
                showDeleteConfirm(
                  data.filter((u) => {
                    let is;
                    for (let i = 0; i < selectedRowKeys.length; i++) {
                      if (u.key === selectedRowKeys[i]) {
                        is = true;
                        break;
                      } else {
                        is = false;
                      }
                    }
                    return is;
                  })
                );
              }}
              style={{ color: "#BC2323" }}
            >
              Disable
            </span>
          </>
        )}
      </div>
      <div style={isMobile ? { width: "70vw" } : {}}>
        <Table
          scroll={{ x: "auto" }}
          loading={loading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          size="small"
          style={{ width: "100%" }}
          footer={() => {
            return (
              <div
                onClick={() => {
                  setIsModalOpen(true);
                }}
                style={{ cursor: "pointer" }}
                className="d-flex fle-row gap-2 px-4 align-items-center"
              >
                <PlusOutlined
                  style={{
                    color: "rgba(46, 108, 223, 1)",
                  }}
                />
                <h6
                  style={{
                    color: "rgba(46, 108, 223, 1)",
                    marginBottom: "0",
                  }}
                >
                  Add Admin
                </h6>
              </div>
            );
          }}
          dataSource={data}
        />
      </div>
      <Modal
        open={isModalOpen}
        footer={(null, null)}
        width={isMobile ? "90vw" : "70vw"}
        style={isMobile ? {} : { top: "5vh" }}
        onCancel={() => {
          setIsModalOpen(false);
          setCurrent(0);
        }}
      >
        <AddAdmin
          setIsModalOpen={setIsModalOpen}
          getAdmins={getUsers}
          current={current}
          setCurrent={setCurrent}
        />
      </Modal>
      <Modal
        open={editAdminOpen}
        footer={(null, null)}
        width={"70vw"}
        style={{ top: "5vh" }}
        onCancel={() => {
          seteditAdminOpen(false);
          setCurrent(0);
        }}
      >
        <EditAdmin
          setIsModalOpen={seteditAdminOpen}
          editAdmin={editAdmin}
          current={current}
          setCurrent={setCurrent}
        />
      </Modal>
    </div>
  );
};

const AddAdmin = ({ setIsModalOpen, getAdmins, current, setCurrent }) => {
  const [admin, setAdmin] = useState({});
  const [privileges, setPrivileges] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [current, setCurrent] = useState(0);
  const [Restricted, setRestricted] = useState([]);
  const [Exclusive, setExclusive] = useState([]);
  const { token } = theme.useToken();
  const [adminType, setadminType] = useState("");

  const next = async () => {
    if (current == 2) {
      await creatRole();
    } else setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    getPrivileges();
    getRoles();
    getUsers();
    getAdmins();
  }, []);

  async function getPrivileges(page = 1, reload = false) {
    setLoading(true);
    var temp = privileges;
    if (reload) {
      temp = [];
    }
    if (privileges.length < (page - 1) * 200 + 2 || reload) {
      var res = await fetchData(
        "admin/privilege?perPage=200&deletedAt=false&page=" + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 10) {
          setPrivileges(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data])
          );
        } else {
          setPrivileges(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setLoading(false);
  }
  async function getUsers(page = 1, reload = false) {
    setLoading(true);
    var temp = users;
    if (reload) {
      temp = [];
    }
    if (users.length < (page - 1) * 10 + 2 || reload) {
      var res = await fetchData(
        "admin/user?disabledAt=false&perPage=10&type=client&deletedAt=false&page=" +
          page
      );
      if (res.status === 200) {
        if (res.data.data.length < 10) {
          setUsers(
            temp
              .filter((a, i) => {
                return a.email;
              })
              .concat([...res.data.data])
          );
        } else {
          setUsers(
            temp
              .filter((a, i) => {
                return a.email;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setLoading(false);
  }
  async function getRoles(page = 1, reload = false) {
    setLoading(true);
    var temp = roles;
    if (reload) {
      temp = [];
    }
    if (roles.length < (page - 1) * 10 + 2 || reload) {
      var res = await fetchData(
        "admin/role?perPage=10&deletedAt=false&page=" + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 10) {
          setRoles(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data])
          );
        } else {
          setRoles(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setLoading(false);
  }
  const creatRole = async () => {
    // alert("meooooooo")
    setLoading(true);
    var res = await putData("admin/user-role", admin);
    if (res.status != 200) {
      Modal.error({ title: res });
    } else {
      setAdmin({ ...admin, id: res.data.data.id });
      setCurrent(3);
    }
    setLoading(false);
  };
  const addPrivilegetoAdmin = async () => {
    setLoading(true);
    if (Exclusive.length || Restricted.length) {
      for (let index = 0; index < Exclusive.length; index++) {
        const element = Exclusive[index];
        var res = await postData(
          `admin/user-role/${admin.id}/exclusive-privilege/` + element
        );
        if (res.status != 201) {
          Modal.error({ title: res });
          setLoading(false);
          return;
        }
      }
      for (let index = 0; index < Restricted.length; index++) {
        const element = Restricted[index];
        var res = await postData(
          `admin/user-role/${admin.id}/restricted-privilege/` + element
        );
        if (res.status != 201) {
          Modal.error({ title: res });
          setLoading(false);
          return;
        }
      }
      Modal.success({ title: res.data.message });
    } else {
      Modal.success({ title: "admin added successfully" });
    }

    setCurrent(0);
    setAdmin({});
    setIsModalOpen(false);
    await getAdmins(1, true);
    setLoading(false);
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "30%",
      //   ...getColumnSearchProps("title"),
    },

    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      //   ...getColumnSearchProps("slug"),
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      //   ...getColumnSearchProps("service"),
    },
  ];

  const rolesColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];
  const usersColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "25%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "20%",
    },
  ];
  const contentStyle = {
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  const steps = [
    {
      title: "Pick User",
      content: (
        <Table
          scroll={{ x: "auto" }}
          loading={loading}
          columns={usersColumns}
          rowSelection={{
            type: "radio",
            onChange: (newSelectedRowKeys, record) => {
              // console.log(newSelectedRowKeys,record[0])
              setAdmin({ ...admin, userId: newSelectedRowKeys[0] });
              setadminType(record[0].type);
            },
          }}
          size="small"
          style={{ width: "100%" }}
          dataSource={users.map((u) => {
            return { ...u, key: u.id, name: u.firstName + " " + u.lastName };
          })}
          pagination={{
            onChange: async (page) => {
              await getUsers(page);
            },
            pageSize: 10,
          }}
        />
      ),
    },
    {
      title: "Change Type",
      content: (
        <div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Change Type
          </div>
          <div
            style={{ textAlign: "center", padding: "40px" }}
            onChange={async (e) => {
              // setLoading(true)
              // console.log(e.target.value)

              var temp = { type: e.target.value };
              var res = await putData(`admin/user/${admin.userId}`, temp);
              if (res.status == 200) {
                Modal.success({ title: res.data.message });
                setadminType(e.target.value);
              } else {
                Modal.error({ title: res });
              }
              // console.log(res)
            }}
          >
            <Radio.Group value={adminType}>
              {/* <Radio value={1}>A</Radio> */}
              <Radio value="client">Client</Radio>
              <Radio value="admin">Admin</Radio>
              {/* <Radio value="super_user">Super User</Radio> */}
            </Radio.Group>
          </div>
        </div>
      ),
    },
    {
      title: "Pick Role",
      content: (
        <Table
          scroll={{ x: "auto" }}
          loading={loading}
          columns={rolesColumns}
          rowSelection={{
            type: "radio",
            onChange: (newSelectedRowKeys) => {
              setAdmin({ ...admin, roleId: newSelectedRowKeys[0] });
            },
          }}
          size="small"
          style={{ width: "100%" }}
          dataSource={roles.map((r) => {
            return { ...r, key: r.id };
          })}
          pagination={{
            onChange: async (page) => {
              await getRoles(page);
            },
            pageSize: 10,
          }}
        />
      ),
    },
    {
      title: "Customize privileges",
      content: (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 my-2 mx-1">
          <div
            className="d-flex flex-column gap-2"
            style={{
              width: "93%",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Exclusive Privilege
            </h1>
            <Table
              scroll={{ x: "auto" }}
              loading={loading}
              columns={columns}
              dataSource={
                roles.filter((r) => {
                  return r.id == admin.roleId;
                })[0]
                  ? roles
                      .filter((r) => {
                        return r.id == admin.roleId;
                      })[0]
                      .privileges.map((l) => {
                        return { ...l.privilege, key: l.privilegeId };
                      })
                  : []
              }
              rowSelection={{
                type: "checkbox",
                Restricted,
                onChange(newSelectedRowKeys) {
                  setExclusive([...newSelectedRowKeys]);
                },
              }}
              size="small"
              pagination={{
                onChange: async (page) => {
                  await getPrivileges(page);
                },
                pageSize: 10,
              }}
            />
          </div>
          <div
            className="d-flex flex-column gap-2 "
            style={{
              width: "93%",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Restricted Privilege
            </h1>
            <Table
              scroll={{ x: "auto" }}
              loading={loading}
              columns={columns}
              dataSource={privileges
                .filter((p) => {
                  return roles.filter((r) => {
                    return r.id == admin.roleId;
                  })[0]
                    ? roles
                        .filter((r) => {
                          return r.id == admin.roleId;
                        })[0]
                        .privileges.filter((pre) => {
                          return pre.privilegeId == p.id;
                        }).length == 0
                    : false;
                })
                .map((l) => {
                  console.log(l);
                  return { ...l, key: l.id };
                })}
              rowSelection={{
                type: "checkbox",
                Restricted,
                onChange(newSelectedRowKeys) {
                  setRestricted([...newSelectedRowKeys]);
                },
              }}
              size="small"
              // style={{ width: "500px" }}
              pagination={{
                onChange: async (page) => {
                  await getPrivileges(page);
                },
                pageSize: 10,
              }}
            />
          </div>
        </div>
      ),
    },
  ];
  const edit_steps = [
    {
      title: "Pick Role",
      content: (
        <Table
          scroll={{ x: "auto" }}
          loading={loading}
          columns={rolesColumns}
          rowSelection={{
            type: "radio",
            onChange: (newSelectedRowKeys) => {
              setAdmin({ ...admin, roleId: newSelectedRowKeys[0] });
            },
          }}
          size="small"
          style={{ width: "100%" }}
          dataSource={roles.map((r) => {
            return { ...r, key: r.id };
          })}
          pagination={{
            onChange: async (page) => {
              await getRoles(page);
            },
            pageSize: 10,
          }}
        />
      ),
    },
    {
      title: "Customize privileges",
      content: (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 my-2 mx-1">
          <div
            className="d-flex flex-column gap-2"
            style={{
              width: "93%",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Exclusive Privilege
            </h1>
            <Table
              scroll={{ x: "auto" }}
              loading={loading}
              columns={columns}
              dataSource={privileges
                .filter((p) => {
                  return roles.filter((r) => {
                    return r.id == admin.roleId;
                  })[0]
                    ? roles
                        .filter((r) => {
                          return r.id == admin.roleId;
                        })[0]
                        .privileges.filter((pre) => {
                          return pre.privilegeId == p.id;
                        }).length == 0
                    : false;
                })
                .map((l) => {
                  console.log(l);
                  return { ...l, key: l.id };
                })}
              rowSelection={{
                type: "checkbox",
                Restricted,
                onChange(newSelectedRowKeys) {
                  setExclusive([...newSelectedRowKeys]);
                },
              }}
              size="small"
              pagination={{
                onChange: async (page) => {
                  await getPrivileges(page);
                },
                pageSize: 10,
              }}
            />
          </div>
          <div
            className="d-flex flex-column gap-2 "
            style={{
              width: "93%",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Restricted Privilege
            </h1>
            <Table
              scroll={{ x: "auto" }}
              loading={loading}
              columns={columns}
              dataSource={
                roles.filter((r) => {
                  return r.id == admin.roleId;
                })[0]
                  ? roles
                      .filter((r) => {
                        return r.id == admin.roleId;
                      })[0]
                      .privileges.map((l) => {
                        return { ...l.privilege, key: l.privilegeId };
                      })
                  : []
              }
              rowSelection={{
                type: "checkbox",
                Restricted,
                onChange(newSelectedRowKeys) {
                  setRestricted([...newSelectedRowKeys]);
                },
              }}
              size="small"
              // style={{ width: "500px" }}
              pagination={{
                onChange: async (page) => {
                  await getPrivileges(page);
                },
                pageSize: 10,
              }}
            />
          </div>
        </div>
      ),
    },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  return (
    <>
      <Steps current={current} items={items} style={{ marginTop: "20px" }} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={async () => {
              await addPrivilegetoAdmin();
            }}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
      </div>
    </>
  );
  // return (
  //   <div
  //     style={{
  //       textAlign: "center",
  //       justifyContent: "center",
  //       alignItems: "center",
  //     }}
  //   >
  //     {current == 1 ? (
  //       <>
  //         {" "}
  //         <h2 style={{ fontSize: "20px", fontWeight: "700" }}>Add Admin</h2>
  //         <div
  //           style={{
  //             width: "95%",
  //             height: "1px",
  //             backgroundColor: "gray",
  //             marginBottom: "15px",
  //           }}
  //         />
  //         <Form
  //           style={{ width: "80%", marginLeft: "10%" }}
  //           layout="vertical"
  //           form={myForm}
  //         >
  //           <Form.Item
  //             name={"email"}
  //             label={"Email"}
  //             rules={[{ required: true, message: "Please input Email" }]}
  //           >
  //             <Input
  //               onChange={(e) => {
  //                 setAdmin({ ...admin, email: e.target.value });
  //               }}
  //             />
  //           </Form.Item>
  //           <Form.Item name={"role"} label={"Role"}>
  //             <Select
  //               onChange={(value) => {
  //                 setAdmin({ ...admin, role: value });
  //               }}
  //             >
  //               {roles.map((r) => {
  //                 return <Select.Option value={r.id}>{r.title}</Select.Option>;
  //               })}
  //             </Select>
  //           </Form.Item>
  //           <Form.Item>
  //             <Button
  //               loading={loading}
  //               type="primary"
  //               onClick={() => creatRole(admin)}
  //             >
  //               Confirm
  //             </Button>
  //           </Form.Item>
  //         </Form>
  //       </>
  //     ) : (
  //       <>
  //         <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
  //           Add privileges of role
  //         </h2>
  //         <div
  //           style={{
  //             width: "95%",
  //             height: "1px",
  //             backgroundColor: "black",
  //             marginBottom: "30px",
  //           }}
  //         />
  //         <Table
  //           loading={loading}
  //           columns={columns}
  //           dataSource={list.map((l) => {
  //             return { ...l, key: l.id };
  //           })}
  //           rowSelection={{ type: "checkbox", ...rowSelection }}
  //           size="small"
  //           style={{ width: "500px" }}
  //           pagination={{
  //             onChange: async (page) => {
  //               await getPrivileges(page);
  //             },
  //             pageSize: 10,
  //           }}
  //         />
  //         <Button
  //           loading={loading}
  //           type="primary"
  //           onClick={() => addPrivilegetoAdmin(admin, selectedRowKeys)}
  //         >
  //           Confirm
  //         </Button>
  //       </>
  //     )}
  //   </div>
  // );
};

const EditAdmin = ({ setIsModalOpen, editAdmin, current, setCurrent }) => {
  const [admin, setAdmin] = useState({
    userId: editAdmin.userId,
    roleId: editAdmin.roleId,
  });
  const [privileges, setPrivileges] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [current, setCurrent] = useState(0);
  const [Restricted, setRestricted] = useState([]);
  const [Exclusive, setExclusive] = useState([]);
  const { token } = theme.useToken();
  const next = async () => {
    if (current == 0) {
      await creatRole();
    } else setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    getPrivileges();
    getRoles();
    getUsers();
    // getAdmins();
  }, []);

  async function getPrivileges(page = 1, reload = false) {
    setLoading(true);
    var temp = privileges;
    if (reload) {
      temp = [];
    }
    if (privileges.length < (page - 1) * 200 + 2 || reload) {
      var res = await fetchData(
        "admin/privilege?perPage=200&deletedAt=false&page=" + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 10) {
          setPrivileges(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data])
          );
        } else {
          setPrivileges(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setLoading(false);
  }
  async function getUsers(page = 1, reload = false) {
    setLoading(true);
    var temp = users;
    if (reload) {
      temp = [];
    }
    if (users.length < (page - 1) * 10 + 2 || reload) {
      var res = await fetchData(
        "admin/user?type[]=client&disabledAt=false&perPage=10&deletedAt=false&page=" +
          page
      );
      if (res.status === 200) {
        if (res.data.data.length < 10) {
          setUsers(
            temp
              .filter((a, i) => {
                return a.email;
              })
              .concat([...res.data.data])
          );
        } else {
          setUsers(
            temp
              .filter((a, i) => {
                return a.email;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setLoading(false);
  }
  async function getRoles(page = 1, reload = false) {
    setLoading(true);
    var temp = roles;
    if (reload) {
      temp = [];
    }
    if (roles.length < (page - 1) * 10 + 2 || reload) {
      var res = await fetchData(
        "admin/role?perPage=10&deletedAt=false&page=" + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 10) {
          setRoles(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data])
          );
        } else {
          setRoles(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setLoading(false);
  }
  const creatRole = async () => {
    setLoading(true);
    var res = await putData("admin/user-role", admin);
    if (res.status != 200) {
      Modal.error({ title: res });
    } else {
      setAdmin({ ...admin, id: res.data.data.id });
      setCurrent(1);
    }
    setLoading(false);
  };
  const addPrivilegetoAdmin = async () => {
    setLoading(true);
    for (let index = 0; index < Exclusive.length; index++) {
      const element = Exclusive[index];
      var res = await postData(
        `admin/user-role/${admin.id}/exclusive-privilege/` + element
      );
      if (res.status != 201) {
        Modal.error({ title: res });
        setLoading(false);
        return;
      }
    }
    for (let index = 0; index < Restricted.length; index++) {
      const element = Restricted[index];
      var res = await postData(
        `admin/user-role/${admin.id}/restricted-privilege/` + element
      );
      if (res.status != 201) {
        Modal.error({ title: res });
        setLoading(false);
        return;
      }
    }
    Modal.success({ title: res.data.message });
    setCurrent(0);
    setAdmin({});
    setIsModalOpen(false);
    // await getAdmins(1, true);
    setLoading(false);
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "30%",
      //   ...getColumnSearchProps("title"),
    },

    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      //   ...getColumnSearchProps("slug"),
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      //   ...getColumnSearchProps("service"),
    },
  ];

  const rolesColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const contentStyle = {
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const edit_steps = [
    {
      title: "Pick Role",
      content: (
        <Table
          scroll={{ x: "auto" }}
          loading={loading}
          columns={rolesColumns}
          rowSelection={{
            type: "radio",
            onChange: (newSelectedRowKeys) => {
              setAdmin({ ...admin, roleId: newSelectedRowKeys[0] });
            },
            defaultSelectedRowKeys: [editAdmin.roleId],
          }}
          size="small"
          style={{ width: "100%" }}
          dataSource={roles.map((r) => {
            return { ...r, key: r.id };
          })}
          pagination={{
            onChange: async (page) => {
              await getRoles(page);
            },
            pageSize: 10,
          }}
        />
      ),
    },
    {
      title: "Customize privileges",
      content: (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 my-2 mx-1">
          <div
            className="d-flex flex-column gap-2"
            style={{
              width: "93%",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Exclusive Privilege
            </h1>
            <Table
              scroll={{ x: "auto" }}
              loading={loading}
              columns={columns}
              dataSource={
                roles.filter((r) => {
                  return r.id == admin.roleId;
                })[0]
                  ? roles
                      .filter((r) => {
                        return r.id == admin.roleId;
                      })[0]
                      .privileges.map((l) => {
                        return { ...l.privilege, key: l.privilegeId };
                      })
                  : []
              }
              rowSelection={{
                type: "checkbox",
                Restricted,
                onChange(newSelectedRowKeys) {
                  setExclusive([...newSelectedRowKeys]);
                },
                defaultSelectedRowKeys: editAdmin.exclusivePrivileges.map(
                  (r) => {
                    return r.privilegeId;
                  }
                ),
              }}
              size="small"
              pagination={{
                onChange: async (page) => {
                  await getPrivileges(page);
                },
                pageSize: 10,
              }}
            />
          </div>
          <div
            className="d-flex flex-column gap-2 "
            style={{
              width: "93%",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Restricted Privilege
            </h1>
            <Table
              scroll={{ x: "auto" }}
              loading={loading}
              columns={columns}
              dataSource={privileges
                .filter((p) => {
                  return roles.filter((r) => {
                    return r.id == admin.roleId;
                  })[0]
                    ? roles
                        .filter((r) => {
                          return r.id == admin.roleId;
                        })[0]
                        .privileges.filter((pre) => {
                          return pre.privilegeId == p.id;
                        }).length == 0
                    : false;
                })
                .map((l) => {
                  return { ...l, key: l.id };
                })}
              rowSelection={{
                type: "checkbox",
                Restricted,
                onChange(newSelectedRowKeys) {
                  setRestricted([...newSelectedRowKeys]);
                },
                defaultSelectedRowKeys: editAdmin.restrictedPrivileges.map(
                  (r) => {
                    return r.privilegeId;
                  }
                ),
              }}
              size="small"
              // style={{ width: "500px" }}
              pagination={{
                onChange: async (page) => {
                  await getPrivileges(page);
                },
                pageSize: 10,
              }}
            />
          </div>
        </div>
      ),
    },
  ];
  const items = edit_steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  return (
    <>
      <Steps current={current} items={items} style={{ marginTop: "20px" }} />
      <div style={contentStyle}>{edit_steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < edit_steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === edit_steps.length - 1 && (
          <Button
            type="primary"
            onClick={async () => {
              await addPrivilegetoAdmin();
            }}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
      </div>
    </>
  );
  // return (
  //   <div
  //     style={{
  //       textAlign: "center",
  //       justifyContent: "center",
  //       alignItems: "center",
  //     }}
  //   >
  //     {current == 1 ? (
  //       <>
  //         {" "}
  //         <h2 style={{ fontSize: "20px", fontWeight: "700" }}>Add Admin</h2>
  //         <div
  //           style={{
  //             width: "95%",
  //             height: "1px",
  //             backgroundColor: "gray",
  //             marginBottom: "15px",
  //           }}
  //         />
  //         <Form
  //           style={{ width: "80%", marginLeft: "10%" }}
  //           layout="vertical"
  //           form={myForm}
  //         >
  //           <Form.Item
  //             name={"email"}
  //             label={"Email"}
  //             rules={[{ required: true, message: "Please input Email" }]}
  //           >
  //             <Input
  //               onChange={(e) => {
  //                 setAdmin({ ...admin, email: e.target.value });
  //               }}
  //             />
  //           </Form.Item>
  //           <Form.Item name={"role"} label={"Role"}>
  //             <Select
  //               onChange={(value) => {
  //                 setAdmin({ ...admin, role: value });
  //               }}
  //             >
  //               {roles.map((r) => {
  //                 return <Select.Option value={r.id}>{r.title}</Select.Option>;
  //               })}
  //             </Select>
  //           </Form.Item>
  //           <Form.Item>
  //             <Button
  //               loading={loading}
  //               type="primary"
  //               onClick={() => creatRole(admin)}
  //             >
  //               Confirm
  //             </Button>
  //           </Form.Item>
  //         </Form>
  //       </>
  //     ) : (
  //       <>
  //         <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
  //           Add privileges of role
  //         </h2>
  //         <div
  //           style={{
  //             width: "95%",
  //             height: "1px",
  //             backgroundColor: "black",
  //             marginBottom: "30px",
  //           }}
  //         />
  //         <Table
  //           loading={loading}
  //           columns={columns}
  //           dataSource={list.map((l) => {
  //             return { ...l, key: l.id };
  //           })}
  //           rowSelection={{ type: "checkbox", ...rowSelection }}
  //           size="small"
  //           style={{ width: "500px" }}
  //           pagination={{
  //             onChange: async (page) => {
  //               await getPrivileges(page);
  //             },
  //             pageSize: 10,
  //           }}
  //         />
  //         <Button
  //           loading={loading}
  //           type="primary"
  //           onClick={() => addPrivilegetoAdmin(admin, selectedRowKeys)}
  //         >
  //           Confirm
  //         </Button>
  //       </>
  //     )}
  //   </div>
  // );
};
export default Admins;
