import React from "react";
import { Tabs } from "antd";
import { useState } from "react";
import access from "../../assets/access";
import Cookies from "js-cookie";
import { useMobile } from "../../hooks";
import full from "../../assets/fullAccess";
import Unverified from "./Unverified";
import Service from "./Service";
import No_Show from "./No_Show";
import Delay from "./Delay";
import Final from "./Final";
import Todo_Reply from "./Reply";
import CommentPage from "./comment/commentPage";
import Translators from './Translators'
import "./MobileTodoStyle.scss";

function MobileTodo({ activekey }) {
  const isMobile = useMobile();
  const [privilage, setPrivilage] = useState(Cookies.get("privilege") ?? full);

  const [DefaultKey, setDefaultKey] = useState(activekey ?? 1);

  const onChange = (key) => {
    setDefaultKey(key);
  };
  const items = [
    privilage.indexOf(access.todoList.showPatient) > -1 && {
      key: 1,
      label: "Unverified Patients",
      children: <Unverified />,
    },
    privilage.indexOf(access.todoList.showStep) > -1 && {
      key: 2,
      label: "Service Steps",
      children: <Service />,
    },
    privilage.indexOf(access.todoList.showappointment) > -1 && {
      key: 3,
      label: "No-Show Steps",
      children: <No_Show />,
    },
    privilage.indexOf(access.todoList.showStep) > -1 && {
      key: 4,
      label: "Delay In Actions",
      children: <Delay />,
    },
    privilage.indexOf(access.todoList.showpurchase) > -1 && {
      key: 5,
      label: "Final Approval",
      children: <Final />,
    },
    privilage.indexOf(access.todoList.indexReply) > -1 && {
      key: 6,
      label: "Service Steps Reply",
      children: <Todo_Reply />,
    },
    {
      key:7,
      label:'Need Translator',
      children:<Translators/>
    },
    privilage.indexOf(access.todoList.indexComment) > -1 && {
      key: 8,
      label: "Internal Comments",
      children: <CommentPage />,
    },
  ];

  return (
    <>
      <div
      className="MobileTodo"
        style={{
          minHeight: "150vh",
          minWidth: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            padding: "0px 0px",
            // textAlign: "center",
            margin: "auto",
            minHeight: "50%",
          }}
        >
          <Tabs activeKey={DefaultKey} items={items} onChange={onChange} />
        </div>
      </div>
    </>
  );
}

export default MobileTodo;
