import { React, useState, useRef, useEffect } from "react";
import "./styles.scss";
import {
  SearchOutlined,
  ExclamationCircleFilled,
  DownOutlined,
  UpOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table, Tag } from "antd";
import Highlighter from "react-highlight-words";
import { deleteData, fetchData, putData } from "../../endpoints";
import PatientPage from "../patientPage";
import { Modal } from "antd";
import { useMobile } from "../../hooks";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import access from "../../assets/access";

import AddComment from "../ToDo/comment/addComment";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const PatientManagement = () => {
  const isMobile = useMobile();
  const { patientId } = useParams();
  const path = useLocation().pathname;
  const navigator = useNavigate();
  const [patients, setPatients] = useState([]);
  const [removedPatients, setRemovedPatients] = useState([]);
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [patientLoading, setPatientLoading] = useState(true);
  const [showRemove, setShowRemove] = useState(false);
  const [serviceTypesarray, setServiceTypes] = useState([]);
  const [domain, setDomain] = useState("");
  const [selected, setSelected] = useState({});
  const [addnotemodal, setaddnotemodal] = useState(false);
  const getPatient = async (page = 1, reload = false) => {
    var temp = patients;
    if (reload) {
      temp = [];
    }
    setPatientLoading(true);
    if (patients.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/patient/?deletedAt=false&perPage=15&page=` + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
        } else {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), serviceTypes: [] },
              ])
          );
        }
      }
    }

    setPatientLoading(false);
  };
  const getConfig = async () => {
    var res2 = await fetchData("user/config");
    if (res2.status == 200) {
      setDomain(res2.data.data.storage.bucket.url + "/");
    }
  };
  const getRemovedPatient = async (page = 1) => {
    setLoading(true);
    if (removedPatients.length < (page - 1) * 15 + 2) {
      var res2 = await fetchData(
        `admin/patient/?deletedAt=true&perPage=15&page=` + page
      );
      if (res2.status == 200) {
        if (res2.data.data.length < 15) {
          setRemovedPatients(
            removedPatients
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res2.data.data)
          );
        } else {
          setRemovedPatients(
            removedPatients
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([
                ...res2.data.data,
                { deletedAt: new Date().toString(), serviceTypes: [] },
              ])
          );
        }
      }
    }
    setLoading(false);
  };
  const getServiceTypes = async () => {
    var res = await fetchData("admin/service-type");
    if (res.status == 200) {
      var temp = [];
      res.data.data.map((s) => {
        temp = [...temp, s];
        if (s.children) {
          temp = temp.concat(s.children);
        }
      });
      setServiceTypes(temp);
    }
  };
  const getOnePatient = async (id) => {
    setLoading(true);
    var res = await fetchData(`admin/patient/${id}`);
    if (res.status == 200) {
      setPatient({
        ...res.data.data,
        name: res.data.data.firstName + " " + res.data.data.lastName,
      });
      setIsOpen(true);
    } else {
      // Modal.error({ title: res });
      // navigator(path.replace("/" + patientId, ""), {
      //   state: { previousPath: path },
      // });

      return navigator("/404");
    }
    setLoading(false);
  };
  useEffect(() => {
    if (
      patient == null &&
      patientId &&
      privilage.indexOf(access.patientManegment.showPatient) > -1
    ) {
      getOnePatient(patientId);
      getConfig();
      return;
    }
    getServiceTypes();
    getRemovedPatient();
    getPatient();
    getConfig();
  }, []);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchInput = useRef(null);
  const { confirm } = Modal;

  const showDeleteConfirm = async (arr) => {
    confirm({
      title: "Are you sure you want to disable this patient?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeGrupe(arr);
      },
      onCancel() {},
    });
  };

  const removePatient = async (id) => {
    setLoading(true);
    var res = await deleteData(`admin/patient/${id}`);
    if (res.status === 200) {
      setLoading(false);
      return true;
    } else {
      setLoading(false);
      return false;
    }
  };
  const removeGrupe = async (listOfPatients) => {
    let isOk = true;
    await listOfPatients.map(async (e) => {
      var res = await removePatient(e.id);
      if (!res) {
        isOk = false;
      }
    });
    if (isOk) {
      await getPatient(1, true);
      setSelectedRowKeys([]);
      Modal.success({ title: "Patient Deactivate Successfully." });
    } else {
      Modal.error({ title: "There was a problem, please try again." });
    }
  };
  const verifyPatient = async (id) => {
    var res = await putData(`admin/patient/${id}/verify`);
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  const verifyGrupe = async (listOfPatients) => {
    setLoading(true);
    let isOk = true;
    for (var e in listOfPatients) {
      var res = await verifyPatient(listOfPatients[e].id);
      if (!res) {
        isOk = false;
      }
    }

    if (isOk) {
      await getPatient(1, true);
      setSelectedRowKeys([]);
      setLoading(false);
      Modal.success({ title: "Patients have been successfully verifyed." });
    } else {
      setLoading(false);
      Modal.error({ title: "There was a problem, please try again." });
    }
  };
  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    // confirm();
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length == 0) {
      await getPatient();
    } else {
      let search = "";
      if (dataIndex == "name") search = "firstName";
      else search = "id";
      var res = await fetchData(`admin/patient/?${search}=` + selectedKeys[0]);
      if (res.status == 200) {
        setPatients(
          res.data.data.filter((u) => {
            return !u.deletedAt;
          })
        );
      }
    }
    setLoading(false);
  };
  const handleRemoveSearch = async (selectedKeys, confirm, dataIndex) => {
    // confirm();
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      await getPatient();
    } else {
      let search = "";
      if (dataIndex == "name") search = "firstName";
      else search = "id";
      var res = await fetchData(`admin/patient/?${search}=` + selectedKeys[0]);
      if (res.status == 200) {
        setRemovedPatients(
          res.data.data.filter((u) => {
            return u.deletedAt;
          })
        );
      }
    }
    setLoading(false);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    await getPatient(1, true);
  };
  const getColumnSearchProps = (dataIndex, isRemove = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            isRemove
              ? handleRemoveSearch(selectedKeys, confirm, dataIndex)
              : handleSearch(selectedKeys, confirm, dataIndex);
          }}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              isRemove
                ? handleRemoveSearch(selectedKeys, confirm, dataIndex)
                : handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) == index);
  }
  // const filterServiceType = async (pagination, filters, sorter, extra) => {
  //   if (pagination) {
  //     await getPatient(pagination.current);
  //   }
  //   if (filters.serviceTypes.length > 0) {
  //     setLoading(true);
  //     var p = [];
  //     for (const s of filters.serviceTypes) {
  //       var res = await fetchData(`admin/patient/?serviceTypes=` + s);
  //       if (res.status == 200) {
  //         p = p.concat(
  //           res.data.data.filter((u) => {
  //             return !u.deletedAt;
  //           })
  //         );
  //       }
  //     }
  //     // p = removeDuplicates(p);
  //     // p = [...new Set(p)];
  //     setPatients(p);
  //     setLoading(false);
  //   } else {
  //     await getPatient(1, true);
  //   }
  // };

  const columns2 = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      width: "20%",
      onCell: (record) => {
        return {
          onClick: () => {
            // setPatient(record);
            // navigator(path + "/" + record.id, {
            //   state: { previousPath: path },
            // });
            // setIsOpen(true);
          },
        };
      },
      ...getColumnSearchProps("name", true),
      render: (record) =>
        privilage.indexOf(access.patientManegment.showPatient) > -1 ? (
          <a
            href={path + "/" + record.id}
            style={{ cursor: "pointer", color: "black" }}
            className="name-table"
          >
            {record.name}
          </a>
        ) : (
          <p className="name-table">{record.name}</p>
        ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : (
          privilage.indexOf(access.patientManegment.verifyPatient) > -1 && (
            <span
              onClick={async () => {
                await verifyGrupe([record]);
              }}
              style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}
            >
              Verify
            </span>
          )
        ),
      width: "15%",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Service",
      dataIndex: "serviceTypes",
      key: "serviceTypes",
      width: "25%",
      responsive: ["md"],
      render: (serviceTypes, record) => (
        <div className="d-flex flex-column gap-2">
          {serviceTypes &&
            serviceTypes.map((s, i) => {
              let color = ["geekblue", "purple", "magenta"];
              return (
                <Tag color={color[i % 3]} key={s}>
                  {s.serviceType.title}
                </Tag>
              );
            })}
        </div>
      ),
      // filters: serviceTypesarray
      //   .filter((t) => {
      //     return t.children.length > 0;
      //   })
      //   .map((t) => {
      //     return {
      //       text: t.title,
      //       children: t.children.map((s) => {
      //         return { text: s.title, value: s.id };
      //       }),
      //     };
      //   }),
      // filterMode: "tree",
      // onFilterReset: async () => {
      //   await getPatient(1, true);
      // },
      // onFilter: (value, record) => isTher(value, record),
    },
    {
      title: "Date Deleted",
      dataIndex: "deletedAt",
      key: "deletedAt",
      responsive: ["md"],
      ...getColumnSearchProps("deletedAt"),
      sorter: (a, b) => new Date(a.deletedAt) - new Date(b.deletedAt),
      sortDirections: ["descend", "ascend"],
    },
  ];
  function isTher(value, record) {
    let is = false;
    record.serviceTypes.forEach((element) => {
      if (element.serviceType.title == value) {
        is = true;
      }
      // console.log(element);
    });
    return is;
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      width: "25%",
      ...getColumnSearchProps("name"),
      render: (record) =>
        privilage.indexOf(access.patientManegment.showPatient) > -1 ? (
          <a
            href={path + "/" + record.id}
            style={{ cursor: "pointer", color: "black" }}
            className="name-table"
          >
            {record.name}
          </a>
        ) : (
          <p className="name-table">{record.name}</p>
        ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : (
          privilage.indexOf(access.patientManegment.verifyPatient) > -1 && (
            <span
              onClick={async () => {
                await verifyGrupe([record]);
              }}
              style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}
            >
              Verify
            </span>
          )
        ),
      width: "15%",
    },
    {
      title: "Service",
      dataIndex: "serviceTypes",
      key: "serviceTypes",
      width: "25%",
      responsive: ["md"],
      render: (serviceTypes, record) => (
        <div className="d-flex flex-column gap-2">
          {serviceTypes &&
            serviceTypes.map((s, i) => {
              let color = ["geekblue", "purple", "magenta"];

              return (
                <Tag color={color[i % 3]} key={s}>
                  {s.serviceType.title}
                </Tag>
              );
            })}
        </div>
      ),
      // filters: serviceTypesarray
      //   .filter((t) => {
      //     return t.children.length > 0;
      //   })
      //   .map((t) => {
      //     return {
      //       key: t.id,
      //       text: t.title,
      //       children: t.children.map((s) => {
      //         return { text: s.title, value: s.id, key: s.id };
      //       }),
      //     };
      //   }),
      // // onFilter: (value, record) => isTher(value, record),
      // filterMode: "tree",
      // filterIcon: <FilterOutlined />,
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        privilage.indexOf(access.todoList.addComment) > -1 && (
          <div
            style={{
              // marginRight: "15px",
              alignItems: "center",
              justifyContent: "center",
            }}
            className=" d-flex flex-row "
          >
            <a
              onClick={() => {
                setSelected({
                  patientId: u.id,
                  firstName: u.firstName,
                  lastName: u.lastName,
                });
                setaddnotemodal(true);
              }}
              style={{ color: "black" }}
            >
              Add comment
            </a>
          </div>
        ),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        privilage.indexOf(access.patientManegment.disablePatient) > -1 && (
          <div
            style={{
              marginRight: "15px",
              alignItems: "center",
              justifyContent: "center",
            }}
            className=" d-flex flex-row gap-4"
          >
            <a
              onClick={async (e) => {
                e.stopPropagation();
                await showDeleteConfirm([u]);
              }}
              style={{ color: "#BC2323" }}
            >
              Disable
            </a>
          </div>
        ),
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const data = patients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: new Date(p.createdAt).toDateString(),
    };
  });
  const removedData = removedPatients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      deletedAt: new Date(p.deletedAt).toDateString(),
    };
  });

  if (isOpen) {
    return (
      <PatientPage
        patient={patient}
        setPatient={setPatient}
        setIsOpen={setIsOpen}
        loading={loading}
        setLoading={setLoading}
        isMobile={isMobile}
        serviceTypes={serviceTypesarray}
        getPatients={getPatient}
        getServiceTypes={getServiceTypes}
        domain={domain}
      />
    );
  } else {
    return (
      <div
        style={{ backgroundColor: "white", minHeight: "100vh" }}
        className={
          isMobile
            ? "d-flex flex-column w-100"
            : "d-flex flex-column w-100 py-5 px-5"
        }
        dir="ltr"
      >
        <div className={`${isMobile ? "col-11 mx-3" : "col-11 mx-3"}`}>
          <div className="table-box w-100 py-4 align-items-center">
            <div className="d-flex flex-row align-items-center px-4 py-4 w-100">
              <span
                style={
                  isMobile
                    ? { fontSize: "18px", fontWeight: "700", width: "15%" }
                    : { fontSize: "24px", fontWeight: "700", width: "15%" }
                }
              >
                Patients
              </span>
              <div
                className="d-flex flex-row gap-3"
                style={{
                  justifyContent: "flex-end",
                  width: "85%",
                  fontWeight: "bold",
                }}
              >
                {selectedRowKeys.length > 0 && (
                  <>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedRowKeys([]);
                      }}
                    >
                      Cancel
                    </span>
                    {privilage.indexOf(access.patientManegment.verifyPatient) >
                      -1 && (
                      <span
                        onClick={async () => {
                          verifyGrupe(
                            data.filter((u) => {
                              let is;
                              for (let i = 0; i < selectedRowKeys.length; i++) {
                                if (u.key === selectedRowKeys[i]) {
                                  is = true;
                                  break;
                                } else {
                                  is = false;
                                }
                              }
                              return is;
                            })
                          );
                        }}
                        style={{
                          color: "rgba(46, 108, 223, 1)",
                          cursor: "pointer",
                        }}
                      >
                        Verify
                      </span>
                    )}
                    {privilage.indexOf(access.patientManegment.disablePatient) >
                      -1 && (
                      <span
                        onClick={async () => {
                          await showDeleteConfirm(
                            data.filter((u) => {
                              let is;
                              for (let i = 0; i < selectedRowKeys.length; i++) {
                                if (u.key === selectedRowKeys[i]) {
                                  is = true;
                                  break;
                                } else {
                                  is = false;
                                }
                              }
                              return is;
                            })
                          );
                        }}
                        style={{ color: "#BC2323", cursor: "pointer" }}
                      >
                        Disable
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <Table
              scroll={{ x: "auto" }}
              loading={loading || patientLoading}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={columns}
              // onChange={filterServiceType}
              dataSource={data}
              size="small"
              style={{ width: "100%" }}
              pagination={{
                onChange: async (page) => {
                  await getPatient(page);
                },
                pageSize: 15,
              }}
            />
          </div>
          <div className="table-box w-100 py-4 mx-3 align-items-center">
            <div
              style={
                isMobile
                  ? {
                      width: "100%",
                      textAlign: "left",
                      fontSize: "18px",
                      fontWeight: "700",
                      marginBottom: "1.5rem",
                    }
                  : {
                      width: "100%",
                      textAlign: "left",
                      fontSize: "24px",
                      fontWeight: "700",
                      marginBottom: "1.5rem",
                    }
              }
            >
              <span style={{ marginRight: "15px" }}>Disabled Patients</span>
              {!showRemove ? (
                <DownOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setShowRemove(true);
                  }}
                />
              ) : (
                <UpOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setShowRemove(false);
                  }}
                />
              )}
            </div>
            {showRemove && (
              <Table
                scroll={{ x: "auto" }}
                loading={loading}
                pagination={{
                  onChange: async (page) => {
                    await getRemovedPatient(page);
                  },
                  pageSize: 15,
                }}
                columns={columns2}
                dataSource={removedData}
                size="small"
                style={{ width: "100%" }}
              />
            )}
          </div>
        </div>
        <Modal
          title=""
          open={addnotemodal}
          top={"0"}
          width={isMobile ? "90vw" : "65vw"}
          footer={null}
          onCancel={() => setaddnotemodal(false)}
          closeIcon={null}
          destroyOnClose
        >
          <AddComment
            isOpen={addnotemodal}
            setIsOpen={setaddnotemodal}
            getComments={() => {}}
            patients={[
              {
                id: selected.patientId,
                firstName: selected.firstName,
                lastName: selected.lastName,
              },
            ]}
            purchase={null}
            defultComment={{ ...selected, patient: { ...selected } }}
            setDefult={setSelected}
          />
        </Modal>
      </div>
    );
  }
};

export default PatientManagement;
